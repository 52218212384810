"use strict";

jQuery(document).ready(function ($) {
  $('.gallery-slider').slick({
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    infinite: false,
    adaptiveHeight: true,
    prevArrow: $('.arrow-gallery-prev'),
    nextArrow: $('.arrow-gallery-next'),
    responsive: [{
      breakpoint: 991,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        infinite: true,
        dots: true
      }
    }]
  });
});
"use strict";

jQuery(document).ready(function (jQuery) {
  function GetTodayDate() {
    var tdate = new Date();
    var dd = tdate.getDate(); //yields day
    var MM = tdate.getMonth(); //yields month
    var yyyy = tdate.getFullYear(); //yields year
    var currentDate = MM + 1 + "/" + dd + "/" + yyyy;
    return currentDate;
  }
  jQuery('input[name="daterange"]').daterangepicker({
    "minDate": GetTodayDate(),
    "locale": {
      "format": "MM/DD/YYYY",
      "separator": " - ",
      "applyLabel": "Selecteer datums",
      "cancelLabel": "Verwijder datums",
      "fromLabel": "Van",
      "toLabel": "Tot",
      "customRangeLabel": "Custom",
      "weekLabel": "W",
      "daysOfWeek": ["Zo", "Ma", "Di", "Wo", "Do", "Vr", "Za"],
      "monthNames": ["Januari", "Februari", "Maart", "April", "Mei", "Juni", "Juli", "Augustus", "September", "Oktober", "November", "December"],
      "firstDay": 1
    }
  });
  jQuery('input[name="daterange"]').val('');
  jQuery('input[name="daterange"]').attr("placeholder", "Start- en einddatum");
  var period_selector = jQuery('#period_selector');
  var bookings_form = jQuery('form.cart');
  jQuery('#wc_bookings_field_duration').val('0');
  jQuery("#wc-bookings-booking-form > fieldset").on('date-selected', function (event, fdate) {
    var select_data = period_selector.find(':selected').attr('booking-period');
    var select_data_days = period_selector.find(':selected').attr('booking-days');
    jQuery('#chosen-date').val(fdate + ' - ' + toLocal(addDays(fdate, parseInt(select_data_days))));
    var post_id = jQuery('#post_id').val();
    jQuery.ajax({
      type: "post",
      dataType: "json",
      url: trsn_vars.ajaxurl,
      data: {
        action: "trsn_calculate_price",
        period: select_data,
        id: post_id,
        start_date: fdate,
        end_date: toLocal(addDays(fdate, parseInt(select_data_days)))
      },
      success: function success(response) {
        var price = response.data.price;
        var available = response.data.available;
        jQuery('.price-container').show();
        if (available === 1) {
          jQuery('#booking-price').html(price);
        } else if (available === 0) {
          jQuery('#booking-price').html('Niet beschikbaar');
          // jQuery('.wc-bookings-booking-form-button').addClass('disabled');
        }
      }
    });
  });

  function addDays(date, days) {
    var result = new Date(date);
    result.setDate(result.getDate() + days);
    return result;
  }
  function toLocal(date) {
    var local = new Date(date);
    local.setMinutes(date.getMinutes() - date.getTimezoneOffset());
    return local.toJSON().slice(0, 10);
  }
  period_selector.on('change', function () {
    jQuery('.price-container').hide();
    jQuery('.booking-message').hide();
    jQuery('#wc-bookings-booking-form fieldset').removeClass('disabled-before-choose');
    var select_data = jQuery(this).find(':selected').attr('booking-period');
    var pickup_day = jQuery(this).find(':selected').attr('booking-pickup-day');
    var pre_season_available = jQuery(this).find(':selected').attr('pre-season-available');
    var high_season_available = jQuery(this).find(':selected').attr('high-season-available');
    var late_season_available = jQuery(this).find(':selected').attr('late-season-available');
    var select_value = this.value;
    jQuery('#wc_bookings_field_duration').val(select_value);
    jQuery('.wc-bookings-date-picker table tr td').removeClass('bookable-range');
    jQuery('.wc-bookings-date-picker table tr td').addClass('bookable');
    jQuery('.wc-bookings-date-picker table tr td a').removeClass('ui-state-active');
    var bookings_datepicker = jQuery('.wc-bookings-date-picker');
    bookings_datepicker.attr('id', 'wc-' + select_data);
    bookings_datepicker.attr("data-pickup-day", pickup_day);
    if (typeof pre_season_available !== "undefined") {
      bookings_datepicker.attr("pre-season-available", 'pre-season-not-available');
    } else {
      bookings_datepicker.removeAttr("pre-season-available");
    }
    if (typeof high_season_available !== "undefined") {
      bookings_datepicker.attr("high-season-available", 'high-season-not-available');
    } else {
      bookings_datepicker.removeAttr("high-season-available");
    }
    if (typeof late_season_available !== "undefined") {
      bookings_datepicker.attr("late-season-available", 'late-season-not-available');
    } else {
      bookings_datepicker.removeAttr("late-season-available");
    }
    jQuery('#period_selector option').each(function () {
      if (jQuery(this).not(':disabled')) {
        bookings_form.slideDown();
      }
    });
  });
  jQuery(document).foundation();
  var nav = jQuery("nav#site-navigation");
  jQuery('button#openMenu').click(function () {
    nav.addClass("open");
  });
  jQuery('button#primary-mobile-menu').click(function () {
    nav.removeClass("open");
  });
  var ul = jQuery("nav#site-navigation ul li ul");
  var li = jQuery("nav#site-navigation ul li ul li");
  if (ul.hasClass("children")) {
    ul.parent(li).addClass("hasChildren");
  }
  if (li.hasClass("hasChildren")) {
    jQuery("li.hasChildren a").append("<a href='javascript:void(0)' class='expandChild' title='Child item'></a>");
  }
  jQuery(document).mouseup(function (e) {
    if (!nav.is(e.target) // if the target of the click isn't the container...
    && nav.has(e.target).length === 0)
      // ... nor a descendant of the container
      {
        nav.removeClass('open');
      }
  });
  jQuery(document).ready(function () {
    jQuery('.open-search-from').click(function () {
      jQuery('section').addClass("open-search");
      jQuery('body').addClass("search-main");
    });
    jQuery('#deleteSearch').click(function () {
      jQuery('section').removeClass("open-search");
      jQuery('body').removeClass("search-main");
    });
  });
  jQuery(window).on('scroll touchmove', function () {
    jQuery('.fade-in').each(function () {
      if (isScrolledIntoView(jQuery(this))) {
        jQuery(this).addClass('animation-started');
      }
    });
  });
  jQuery('button#openFilters').click(function () {
    jQuery('aside.filters').addClass('open');
  });
  jQuery('button#closeFilters').click(function () {
    jQuery('aside.filters').removeClass('open');
  });
  var didScroll;
  var lastScrollTop = 0;
  var delta = 5;
  var navbarHeight = jQuery('header').outerHeight();
  jQuery(window).scroll(function (event) {
    didScroll = true;
  });
  setInterval(function () {
    if (didScroll) {
      hasScrolled();
      didScroll = false;
    }
  }, 250);
  function hasScrolled() {
    var st = jQuery(window).scrollTop();

    // Make sure they scroll more than delta
    if (Math.abs(lastScrollTop - st) <= delta) return;

    // If they scrolled down and are past the navbar, add class .nav-up.
    // This is necessary so you never see what is "behind" the navbar.
    if (st > lastScrollTop && st > navbarHeight) {
      // Scroll Down
      jQuery('header').removeClass('nav-down').addClass('nav-up');
    } else {
      // Scroll Up
      if (st + jQuery(window).height() < jQuery(document).height()) {
        jQuery('header').removeClass('nav-up').addClass('nav-down');
      }
    }
    lastScrollTop = st;
  }
});
jQuery.event.special.touchstart = {
  setup: function setup(_, ns, handle) {
    this.addEventListener('touchstart', handle, {
      passive: !ns.includes('noPreventDefault')
    });
  }
};
jQuery.event.special.touchmove = {
  setup: function setup(_, ns, handle) {
    this.addEventListener('touchmove', handle, {
      passive: !ns.includes('noPreventDefault')
    });
  }
};
function isScrolledIntoView(elem) {
  var docViewTop = jQuery(window).scrollTop();
  var docViewBottom = docViewTop + (jQuery(window).height() + 200); // 200px offset for earlier response

  var elemTop = jQuery(elem).offset().top;
  var elemBottom = elemTop + jQuery(elem).height();
  return elemBottom <= docViewBottom && elemTop >= docViewTop;
}